:root {
    --white: #ffffff;
    --light-grey: #F3F6F9;
    --slight-grey: #E7EBEF;
    --grey: #AAAAAA;
    --med-grey: #8B8B8B;
    --dark-grey: #3A3939;
    --light-steel: #505E70;
    --steel: #38485C;
    --dark-steel: #213040;
    --light-blue: #D0E1F2;
    --blue: #007BC7;
    --med-blue: #0b5ed7;
    --med-blue-op: #007BC70D;
    --dark-blue: #0b5ed7;
    --success: #34A853;
    --warning: #FBBC05;
    --danger: #EA4335;

    --bs-body-color: #153244;
}