.detail-title {
    color: #9DA9B7;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 4px 0;
    padding: 0;
}

.detail-text {
    color: #38485C;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    padding: 0;
}