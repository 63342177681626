.dash-breadcrumb-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 16px;
}

.dash-breadcrumb {
    box-shadow: none;
    font-weight: 600;
    font-size: 14px;
}

.dash-breadcrumb ol.breadcrumb {
    margin: 0 !important;
}

li.breadcrumb-item:first-child {
    color: #9EAAB8;
    font-weight: normal;
}

li.breadcrumb-item:not(:last-child).active {
    color: #9EAAB8;
    font-weight: normal;
}

li.breadcrumb-item a {

    text-decoration: none;
    color: #9EAAB8;
}

.breadcrumb-item+.breadcrumb-item::before {
    width: 11px;
    height: 11px;
    content: '' !important;
    border-right: 2.5px solid #aaaaaa;
    border-bottom: 2.5px solid #aaaaaa;
    transform: rotate(-45deg) scale(0.7);
    right: 6px;
    position: relative;
    top: 6px;
    margin-left: 6px;
    margin-right: 6px;
}

li.breadcrumb-item.active:not(:first-child)::before {
    width: 11px;
    height: 11px;
    content: '' !important;
    border-right: 2.5px solid var(--steel);
    border-bottom: 2.5px solid var(--steel);
    transform: rotate(-45deg) scale(0.7);
    right: 6px;
    position: relative;
    top: 6px;
    margin-left: 6px;
    margin-right: 6px;
}