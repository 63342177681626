.dash-navbar {
    top: 0;
    left: -30%;
    width: var(--nav-width);
    height: 100%;
    background-color: var(--white);
    padding: .5rem 1rem 0 0;
    transition: .5s;
    z-index: var(--z-fixed);
}

.dash-navbar {
    position: relative;
    padding: 2rem 1rem 0 0;
    margin-top: 65px;
    height: auto;
    width: var(--nav-width);
    left: 0;
}

.dash-navbar.show {
    width: calc(var(--nav-width) + 156px);
}

.body-pd {
    padding-left: calc(var(--nav-width) + 156px);
}

.dash-nav-list {
    overflow: hidden;
}

.dash-nav-list .dash-nav-link {
    color: var(--med-grey);
}

.dash-nav-name {
    color: var(--light-steel);
    font-size: 15px;
    opacity: 0;
    transition: opacity .5s;
    font-weight: 400;
}

.dash-nav-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.dash-nav-hr {
    border-color: #D1D8E0;
    border-width: 2px;
    margin: .45rem 0rem .45rem 1.2rem;
}

.dash-nav-link {
    display: flex;
    width: max-content;
    align-items: center;
    column-gap: 1.25rem;
    padding: .45rem 2.5rem .45rem 1.7rem;
    margin: 0;
    text-decoration: none;
    background: transparent !important;
}

.dash-nav-toggle-btn {
    position: absolute;
    top: 0;
    left: 100%;
    padding: 0 12px 0 8px;
    margin-top: 15px;
    background-color: var(--white);
    height: 55px;
    border-radius: 0px 12px 12px 0px;
    max-width: 100px;
    border: none;
    transition: .5s;
}

.dash-navbar.show .dash-nav-toggle-btn {
    transform: translateX(-8px);
}

.dash-navbar.show .dash-nav-name {
    opacity: 1;
}

.dash-nav-link:hover {
    opacity: 0.6;
    transition: 0.4s;
}

main#content.dash-content-container {
    transition: 0.4s;
    margin-left: var(--main-margin-left);
    margin-right: var(--main-margin-right);
    margin-bottom: 100px;
}

.dash-nav-link.active,
.dash-nav-link.active .dash-nav-name {
    color: var(--blue);
}

.dash-nav-hr:last-child {
    display: none;
}