.login-section {
    margin-left: 40px;
    margin-bottom: 25px;
}

.login-text-container{
    width: 420px;
}

.login-text-container h1{
    font-size: 36px;
    color: #38485C;
}

.login-text-container h5{
    font-size: 19px;
    color: #38485C;
}

.login-text-container h6{
    font-size: 16px;
    font-weight: 700!important;
    color: #38485C;
}

.login-button-container{
    width: fit-content;
}

.login-hero-image-container {
    display: flex;
    justify-content: center;
    max-height: 412px;
}

.login-hero-image-container img {
    object-fit: contain;
}

@media (width < 768px){
    .login-section {
        margin: 0px 15px;
    }

    .login-text-container{
        width: 100%;
    }

    .login-hero-image-container {
        margin-top: 20px;
    }
}