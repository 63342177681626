.footer {
    background-color: #153244;
    padding: 30px 8px;
    color: var(--light-grey);
    border-top: 3px solid #FFCC00;
}

.footer-container{
    margin: 0px 65px;
}

.footer-hero-logo {
    height: 45px;
}

.footer-social-icon {
    height: 25px;
    margin-left: 37.5px;

    &:hover {
        opacity: 0.5;
    }
}

.footer-icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: fit-content;
    align-content: center;
}

.footer-bottom-divisor {
    border-bottom: 1px solid gray;
}

.footer-puesto-container {
    text-align: right;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0px 20px 0px 0px;
    height: 100%;
    margin: 0px -5px;
}

.footer-puesto-container.last {
    margin-right: 0px;
    border: none;
    padding: 0px;
}

.footer-puesto{
    color: #FFFFFF;
}

.footer-puesto-nombre {
    font-weight: 200;
    font-size: 14px;
    opacity: 0.7;
}

.footer-legals {
    font-size: 14px;
    color: #AAAAAA;
    margin-top: 0px;
    margin-bottom: 10px;
    letter-spacing: 0px;
    font-weight: 300;
}

@media (width < 768px){
    .footer-container{
        margin: 0px 20px;
    }
}