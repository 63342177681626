.input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 1.4rem; */
}

.input-container input {
    position: relative;
    display: flex;
}

.input-container:has(.start-icon-container) input {
    padding-left: 45px;
}

.input-container:has(.end-icon-container) input {
    padding-right: 45px;
}

.start-icon-container,
.end-icon-container {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 3rem;
    z-index: 1000;
}

.start-icon-container {
    left: 4px;
}

.end-icon-container {
    right: 4px;
}

.form-input-custom {
    font-size: 15px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #E6EBF0;
    height: 40px;
    border-radius: 10px !important;
    color: var(--blue) !important;
}

.form-input-custom.form-control-lg {
    height: 48px;
}

.form-input-custom::placeholder {
    color: var(--blue) !important;
}


.form-input-custom:focus {
    border: 2px solid var(--blue) !important;
    box-shadow: none;
}

.input-length {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
}

input[type="date"], input[type="time"] {
    display: block;

}



/*  */

.gray-container {
    .input-container input, textarea {
        background-color: #F3F6F9 !important;
        border: 2px solid #E6EBF0 !important;
        color: #38485C !important;
    }

    &:has(input:disabled) {
        .input-container {
            border-radius: 10px !important;
            background-color: rgb(245, 245, 245);

            input {
                opacity: 0.5;
            }
        }

        h5.label {
            color: #9EAAB8;
        }

    }
}

.gray-container .form-input-custom:focus {
    border-color: var(--blue) !important;
}

.select-label.error .form-input-custom {
    border-color: #C93B3B !important;
}
