h1.section-title {
    font-size: 28px;
    font-weight: 700;
    color: var(--steel);
    margin-bottom: 5px;
    line-height: 38px;
}



h1 {
    font-size: 40px;
    font-weight: 700;
    color: var(--steel);
    margin-bottom: 5px;
    line-height: 38px;
}

h2 {
    font-size: 29px;
    font-weight: 700;
    color: var(--steel);
    line-height: 100%;
}

h2.section-subtitle {
    text-align: left;
    font-size: 18px;
    color: #153244;
    font-weight: 300;
    line-height: 25px;
}

h4 {
    color: var(--steel);
    font-size: calc(1.275rem + .3vw);
    font-weight: 500;
    line-height: 1.2;
}

h5 {
    font-size: 1.15rem;
    font-weight: 400;
    color: var(--steel);
}

h6 {
    font-size: 1rem;
    color: var(--grey);
    font-weight: 300 !important;
}

p {
    color: var(--med-grey);
    line-height: 18px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 1rem;
}

.fc-blue {
    color: var(--blue);
}

.fc-light {
    color: var(--med-grey);
}

.fw-light {
    font-weight: 300 !important;
}

span.sec-text {
    min-width: 100%;
    font-size: 14px;
    text-align: center;
    color: #808284;
}

.small, small {
    font-size: .875em !important;
}


.component-error-msg {
    color: #C93B3B;
    font-size: 13px;
}