#dropdown-basic{
    border: none;
}
.nav-link {
    color: var(--med-blue) !important;
    background-color: var(--med-blue-op);
    font-family: 'Nunito', sans-serif;
    padding: 4px 0px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    font-size: 14px;
    margin: 0px 10px;
    font-weight: 600;
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nav-link.alerts {
    background-color: transparent;
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 0px;
}

.nav-link.alerts::before {
    content: '';
    position: absolute;
    height: 30px;
    width: 1px;
    background-color: var(--med-grey);
    margin-left: -15px;
}

.avatar {
    border-radius: 50rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    float: right;
    position: relative;
    top: 0px
}

.dropdownAvatar .nav-link.dropdown-toggle {
    margin-top: 0px;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent !important;
}

.dropdown-toggle::after {
    content: none;
}

.dropdown .dropdown-menu {
    position: absolute;
    top: 100%;
}

.dropdown .dropdown-menu.show {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #E6EBF0;
    border-radius: 8px;
    width: 338px;
    height: 140px;
}

.dropdown-container{
    width: 65px;
    margin: auto 0px;
}

.avatar-big{
    border-radius: 50rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    float: right;
    position: relative;
    top: 0px;
    width: 65px;
    height: 65px;
}

.avatarImg{
    width: 65px;
    height: 65px;
    border-radius: 50rem;
}

.dropdown-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nav-name{
    text-align: left;
    font: normal normal 600 14px/12px Nunito;
    letter-spacing: 0px;
    color: #38485C;
    margin: 10px 0px;
}

.nav-mail{
    text-align: left;
    font: normal normal normal 14px/12px Nunito;
    letter-spacing: 0px;
    color: #38485C;
    margin-bottom: 10px;
}

.nav-role{
    text-align: left;
    font: normal normal normal 14px/12px Nunito;
    letter-spacing: 0px;
    color: #007BC7;
    margin-bottom: 5px;
}

.dropdown-logout{
    border-top: 1px solid #E6EBF0;
    margin: 0px 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 8px;
}

.log-out-icon{
    height: 20px;
    width: 18px;
}

.dropdown-item{
    padding: 0px 5px;
}

.rotate-icon {
    transition: transform 0.3s ease;
    transform: rotate(-180deg);
}

.no-rotate {
    transition: transform 0.3s ease;
    transform: rotate(0deg);
}