.card {
    padding: 24px;
    border-radius: 12px;
    border-color: var(--slight-grey);
}

.card-spacing-20-40 {
    padding: 20px 40px;
}

.card.bg-primary {
    /* color: var(--bs-card-color) !important; */
    border-color: #3786ee !important;
    background-color: #f5faff !important;
}

.card.bg-primary p span {
    color: #4285f4 !important;
}

.divider {
    width: 1px;
    height: 20px;
    position: relative;
    background-color: var(--grey);
}