header {
    background-color: white;
    height: 72px;
    padding: 0 15px !important;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

header:has(.header-logo-container) {
    height: 66px;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-container:has(.header-logo-container) {
    width: calc(86.333333% + 160px);
    padding: 0 !important;
}

.header-logo {
    height: 28px;
    margin-left: 13px;
}

.header-logo-container {
    width: fit-content;
    font-weight: 600;
    font-size: 18px;
    margin-top: 0px;
    text-decoration: none;
}

.header-logo-container a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.header-logo-container .header-logo {
    margin-right: 20px;
    height: 28px;
}

.header-title{
    font-size: 19px;
    margin: 0px 0px 0px 16px;
    color: #153244;
    opacity: 1;
}

@media (width < 768px){
    .header-container {
        padding-left: 0px;
    }
    
    .header-logo {
        margin-left: 0px;
    }

    .header-title{
        font-size: 19px;
        margin: 0px 0px 0px 10px;
        line-height: 18px;
    }
}