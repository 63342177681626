.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;

    font-weight: bold;
    font-size: 18px;
    padding: 8px 16px;
    line-height: 20px;

    border: 2px solid transparent;
    border-radius: 8px;
    margin: 0;
}

.btn-primary {
    background-color: var(--blue);
}

.btn-primary[disabled] {
    background-color: #E6EBF0;
    border-color: #E6EBF0;
    color: #9EAAB8;
}

.btn-secondary {
    background-color: #F5F9FC;
    color: var(--blue);
    border-color: #F5F9FC;
}

.btn-secondary:hover {
    background-color: #E5F1F9;
    color: var(--blue);
    border-color: #E5F1F9;

}

.btn-light {
    color: var(--med-blue);
}

.btn-first-scrn {
    width: auto;
    margin: 25px 10px;
    min-width: 180px;
}

.btn-sm {
    padding: 8px 16px;
    /* border-radius: 8px; */
    font-size: 18px;
    line-height: 18px;
    /* border: 2px solid var(--blue); */
}

.btn-lg {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    font-size: 18px;
    padding: 6px 16px;
    font-weight: bold;
    /* border-radius: 10px; */
}

.btn.with-plus-icon {
    padding-left: 1.1rem;
}

.btn.with-plus-icon::before {
    content: "+";
    font-size: 1.6rem;
    font-weight: 600;
    margin-right: 8px !important;
}

.btn-sm.with-plus-icon {
    padding-left: 0.9rem;
}

.btn-sm.with-plus-icon::before {
    position: relative;
    top: -1px;
    font-size: 1.6rem;
    line-height: 1.6rem;
    font-weight: 600;
    margin-right: 8px !important;
}

.btn.btn-text {
    color: var(--blue);
    padding: 2px 0px;
    transition: 0.4s;
}

.btn.btn-text:hover {
    opacity: 0.5;
    transition: 0.4s;
}

.btn.btn-text.with-backward-icon::before {
    content: '';
    background-image: url('/public/assets/icons/icon-chevron-left.svg');
    background-size: cover;
    width: 24px;
    height: 24px;
}

.btn.btn-dark.dropdown-toggle {
    display: flex;
    justify-content: space-between;
    padding-left: 15px !important;
    padding-right: 10px !important;
}

.btn.btn-dark {
    background-color: var(--steel) !important;
    border-color: var(--steel) !important;
    color: var(--bs-btn-hover-color) !important;
}

.btn-dark:hover {
    background-color: var(--dark-steel) !important;
    border-color: var(--dark-steel) !important;
}

/* BUTTON LINK */

.btn.btn-link {
    margin: 0;
    padding: 0;
    display: inline;
    text-decoration: none;
    font-size: 1em;
    line-height: 1em;
}