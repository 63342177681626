.dropdown .dropdown-toggle {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
    color: var(--med-blue) !important;
    background-color: var(--med-blue-op) !important;
    border-radius: 0;
}

.dropdown .dropdown-menu {
    position: absolute;
    top: 100%;
}

.dropdown .dropdown-menu.show {
    border: none;
    border-radius: 12px;
    font-size: 15px;
    min-width: 100%;
    top: 100%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    height: fit-content;
}

.acciones-dropdown {
    .dropdown-menu{
        width: 150%;
    }
    .dropdown-toggle {
        border: none;
        border-radius: 50%;
        aspect-ratio: 1/1;
        background-color: transparent !important;
        padding: 0;
    }
    
    .dropdown-toggle:hover{
        background-color: #DBEEFC!important;
    }

    .dropdown-toggle::after {
        content: none;
    }

    .dropdown-item {
        font-size: 14px;
        padding: 12px 16px 12px 16px;
        font-weight: 600;
    }
}