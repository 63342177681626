.nav-tabs {
    margin-top: 20px;
    border-bottom: 2px solid #dee2e6;
    padding: 0;
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link.active {
    background-color: transparent;
    border-color: transparent;
    font-size: 18px;
    color: #41464b !important;
    font-weight: var(--bs-nav-link-font-weight);
}

.nav-tabs .nav-item:first-child,
.nav-tabs .nav-item:first-child .nav-link {
    margin-left: 0;
    padding-left: 0;
}

.nav-tabs .nav-link:hover {
    border-color: transparent;
}


.nav-tabs .nav-link.active {
    color: #4285f4 !important;
}

.nav-tabs .nav-link.active::after {
    content: '';
    width: 100%;
    position: relative;
    background-color: #4285f4;
    height: 5px;
    float: left;
    border-radius: 0px 0px 12px 12px;
    bottom: -10px;
}

.tab-content {
    margin-top: 1.5rem;
}