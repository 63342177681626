.accordion {
    /* border-radius: 50px; */
    /* border: 2px solid black; */
    /* overflow: hidden; */
}

.accordion.disabled {
    pointer-events: none;
}

.accordion.disabled .accordion-button:after,
.accordion.disabled .accordion-button::before {
    display: none;
}

.accordion-item {
    border-radius: 14px !important;
    border: 2px solid #e7ebef;
    border-radius: 50px;
    overflow: hidden;
}

.accordion-button {
    padding: 10px 20px;
    color: var(--steel) !important;
    font-size: 20px;
}

.accordion-button svg {
    margin-right: 20px;
}

.accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
}

.accordion-button:focus {
    border-bottom: 2px solid #e6eaed;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
}

.accordion-button::before {
    background-color: #d0e1f247;
    content: '';
    height: 35px;
    width: 35px;
    border-radius: 50rem;
    float: right;
    position: absolute;
    right: 20px;
    z-index: 0;
}

.accordion-button.collapsed::after {
    transform: rotate(-45deg) !important;
    background-image: none !important;
    top: -2px;
}

.accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}

.accordion-button::after {
    z-index: 1;
    width: 10px;
    height: 10px;
    border-left: 2px solid var(--med-blue);
    border-bottom: 2px solid var(--med-blue);
    transform: rotate(135deg) !important;
    position: relative;
    left: -12px;
    top: 1px;
    background-image: none !important;
}

button.accordion-button .eagContainer {
    opacity: 1;
    transition: 0.4s;
}

button.accordion-button.collapsed .eagContainer {
    opacity: 0;
    pointer-events: none;
}

/* VARIANTE DE ACORDION: accordion-white-blue */
.accordion-white-blue .accordion-item {
    border: none;
    box-shadow: none;
    --bs-accordion-border-color: transparent;
}

.accordion-white-blue .accordion-item .accordion-button {
    padding: 15px 20px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--blue) !important;
    color: var(--white) !important;
}

.accordion-white-blue .accordion-item .accordion-button.collapsed {
    background-color: var(--white)!important;
    color: var(--steel) !important;
    transition: 0.4s;
}

.accordion-white-blue.with-plus-icon .accordion-item .accordion-button::before {
    width: 3px;
    height: 21px;
    right: 41px;
    transform: rotate(270deg);
    transition: 0.4s;
    position: absolute;
    z-index: 0;
    border: none;
    background-color: var(--white);
}

.accordion-white-blue.with-plus-icon .accordion-item .accordion-button::after {
    width: 21px;
    height: 3px;
    border: none;
    top: 0px;
    transition: 0.4s;
    transform: rotate(0deg) !important;
    border: none;
    background-color: var(--white);
}

.accordion-white-blue.with-plus-icon .accordion-item .accordion-button.collapsed::after {
    background-color: var(--steel);
}

.accordion-white-blue.with-plus-icon .accordion-item .accordion-button.collapsed::before {
    transform: rotate(0deg);
    transition: 0.4s;
    background-color: var(--steel);
}