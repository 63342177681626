.custom-toast-success {
    background-color: #F5FCF8 !important;
    color: #38485C !important;
    border: 3px solid #26874A !important;
    border-radius: 8px !important;
    padding: 8px 10px !important;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    text-align: left;
    width: 620px;
    right: 300px;
  }
  
  .custom-toast-error {
    background-color: #FCF5F5 !important;
    color: #38485C !important;
    border: 3px solid #C93B3B !important;
    border-radius: 8px !important;
    padding: 8px 10px !important;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    text-align: left;
    width: 620px;
    right: 300px;
  }

  /*Alertas propias*/
  .alert-container {
    margin-top: 10px;
    }
    
    .alert {
      padding: 15px;
      border-radius: 5px;
      color: white;
      animation: fadeIn 0.5s, fadeOut 0.5s 9.5s;
      transition: opacity 0.5s ease-out;
    }
  
    .alert-text{
      padding-left: 15px;
    }
    
    .alert.success {
      display: flex;
      align-items: center;
      background-color: #F5FCF8 !important;
      color: #38485C !important;
      border: 3px solid #26874A !important;
      border-radius: 8px !important;
      padding: 12px 20px !important;
      font-family: 'Nunito', sans-serif;
      font-size: 16px;
      text-align: left;
      width: 620px;
     }
    
    .alert.error {
      display: flex;
      align-items: center;
      background-color: #FCF5F5 !important;
      color: #38485C !important;
      border: 3px solid #C93B3B !important;
      border-radius: 8px !important;
      padding: 12px 20px !important;
      font-family: 'Nunito', sans-serif;
      font-size: 16px;
      text-align: left;
      width: 620px;
      }
    
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(-10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    
    @keyframes fadeOut {
      from {
        opacity: 1;
        transform: translateY(0);
      }
      to {
        opacity: 0;
        transform: translateY(-10px);
      }
    }
