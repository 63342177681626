.table-heading th {
    text-align: left;
    color: #153244 !important;
    font-size: 16px;
    padding: 20px !important;
    font-weight: bold;
}

.table-container {
    border: none;
    margin-bottom: 15px;
}

/* BORDES DE LA TABLA */
.table-container,
.table-container thead,
.table-container .table-heading,
.table-container .table-heading th:first-child {
    border-top-left-radius: 8px !important;
    border: none;
}

.table-container,
.table-container thead,
.table-container .table-heading,
.table-container .table-heading th:last-child {
    border-top-right-radius: 8px !important;
    border: none;
}

.table-container tbody,
.table-container tbody tr:last-child,
.table-container tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px !important;
    border: none;
}

.table-container tbody,
.table-container tbody tr:last-child,
.table-container tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px !important;
    border: none;
}

/*  */
.table-row {
    height: 54px;
    border: none;
}

.table-row td {
    text-align: left;
    color: #153244 !important;
    font-size: 14px;
    padding: 0px 20px !important;
}

.table-row td img {
    width: 30px;
    padding: 4px;
    border-radius: 50px;
    cursor: pointer;
}

.table-row td img:hover {
    background-color: #DBEEFC;
}

.table-error-message{
    height: 76px;
    margin: auto 0px;
}

.table-error-message p{
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    color: #38485C;
    line-height: 25px;
    margin: 0px;
}

.table-error-message b{
    font-weight: bold;
}

.clear-filters-button{
    background-color: #FFFFFF!important;
    border: 2px solid #E6EBF0!important;
    border-radius: 10px!important;
    white-space: nowrap;
    text-align: left;
    font-family: 'Nunito', sans-serif!important;
    font-size: 16px!important;
    color: #007BC7 !important;
}
