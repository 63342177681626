@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');
@import url(./components/dropdown.css);
@import url(./components/button.css);
@import url(./components/accordion.css);
@import url(./components/modal.css);
@import url(./components/card.css);
@import url(./components/table.css);
@import url(./components/alerts.css);
@import url(./components/tabs.css);
@import url(./components/badge.css);
@import url(./components/select.css);
@import url(./components/input.css);
@import url(./components/radio.css);
@import url(./components/spinner.css);

hr {
    display: block;
    color: #b2b4b6;
    border-width: 2px;
    width: 100%;
    padding: 0px;
    margin: 25px 0px;
    border: 1.6px solid;
}

* {
    font-family: "Nunito", Helvetica, Arial, sans-serif;
}