.select-label {
    width: 100%;
    /* display: flex; */
}

h5.label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
}

.form-select-custom {
    font-size: 18px;
    color: var(--steel);
    border: 2px solid #E6EBF0;
    height: 55px;
    border-radius: 12px !important;
    font-family: 'Open sans', sans-serif;
}



.custom-select__control {
    border-radius: 10px !important;
    font-size: 14px;
    padding: 1px 6px;
    font-weight: 600;
    box-shadow: none !important;
    cursor: pointer!important;
}

.custom-select__indicator-separator {
    display: none;
}

.white-container {
    .custom-select__control {
        background-color: white;
        border: 2px solid #E6EBF0 !important;
        color: var(--blue) !important;
    }

    .custom-select__single-value {
        color: var(--blue) !important;
        width: 100% !important;
    }

    .custom-select__placeholder {
        color: var(--blue) !important;
    }
}

.gray-container {
    &:has(.custom-select--is-disabled) h5.label {
        color: #9EAAB8;
    }

    .custom-select__control {
        background-color: #F3F6F9 !important;
        border: 2px solid #E6EBF0 !important;
        color: #38485C !important;
    }

    .custom-select__single-value {
        color: #38485C !important;
        width: 100% !important;
    }

    .custom-select__placeholder {
        color: #38485C !important;
    }


    .custom-select--is-disabled {
        border-radius: 10px !important;
        background-color: rgb(245, 245, 245);

        .custom-select__control {
            opacity: 0.5;
        }
    }

}

.lg {
    .custom-select__control {
        padding: 6px !important;
        font-size: 16px;
    }
}


.custom-select__option {
    font-size: 14px !important;
}



.select-label.error {
    .custom-select__control {
        border-color: #C93B3B !important;
    }
}

.gray-container .custom-select__control--is-focused {
    border-color: var(--blue) !important;
}