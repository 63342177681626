.home-container{
    display: flex;
    justify-content: space-between;
    height: 280px;
}

.home-cards-container{
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-chart-container{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #F1F1F1;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 280px;
}

.home-cards{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #F1F1F1;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 128px;
}
.home-cards-titles{
    margin: 0px;
    line-height: normal;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #153244;
    margin-top: 16px;
}

.home-cards-titles-container{
    width: 90%;
    margin: 0px 18px;
    height: 26px;
}

.chart-container{
    display: flex;
    justify-content: center;
    height: 110px;
}

.home-cards-divider{
    display: flex;
    justify-content: space-between;
}

.home-title-container{
    display: flex;
    flex-direction: column;
    width: 80%;
}

.home-icon-container{
    width: 20%;
    justify-content: center;
    align-self: center;
    display: flex;
    cursor: pointer;
}