.badge {
    border-radius: 5px;
    font-weight: 200;
    padding: 6px 10px;
    border: 1px solid white;
    width: 125px;
    height: 24px;
    font: normal normal 600 14px/12px Nunito;
}

.badge.bg-warning {
    border-color: #FFA82E;
    color: #D28B27 !important;
    background-color: #FCF9F5 !important;
}


.badge.bg-danger {
    border-color: #C93B3B;
    background-color: #FFF5F5 !important;
    color: #C93B3B !important;
}


.badge.bg-success {
    border-color: #26874A;
    background-color: #F5FCF7 !important;
    color: #26874A !important;
}