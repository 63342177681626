.modal-content {
    border: 2px solid #E4E4E5;
    border-radius: 10px;
    padding: 32px 40px;
}

.modal-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #153244;
}

.modal-header {
    padding: 0 0 8px 0;
    margin: 0;
    border-bottom-color: #F2F5F7;
}

.modal-body {
    padding: 1.5rem 0;
}

.modal-footer {
    margin: 0;
    padding: 16px 0 0 0;
    border-top-color: #F2F5F7;
}


/* SIZES */

.modal-lg {
    max-width: 656px;
}

.modal .btn-lg {
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 1.25rem;
    --bs-btn-border-radius: 0.5rem;
}
.modal-text-limit{
    word-wrap: break-word 
}