.radioCustom {
    /* background-color: red; */
    padding-left: 0;
    margin-left: 0;
    display: flex;
    /* justify-content: center; */
    align-items: center;

    input {
        border: 2px solid #38485C !important;
        border-radius: 50% !important;
        background-color: white !important;
        width: 20px !important;
        height: 20px !important;
        clip: auto;
        margin-left: 0 !important;
        margin-top: 0 !important;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        /* pointer-events: none; */

    }

    input:checked {
        border: 2px solid #007BC7 !important;
        --bs-form-check-bg-image: none;
    }

    input:checked::before {
        content: "";
        position: absolute;
        background-color: #007BC7;
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    label {
        padding-left: 0.7rem;
        border: 0 !important;
        color: #38485C;
        font-weight: 500;
    }
}