.page-item.active .page-link {
    background-color: var(--blue);
    border: 2px solid var(--blue);
    color: var(--white);
}


.page-item .page-link {
    border-radius: 8px;
    margin-right: 10px !important;
    height: 45px;
    line-height: 30px;
    border: 2px solid #E6EBF0;
    color: var(--steel);
    font-size: 18px;
    padding: 6px 12px;
}