.profile-cards{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #F1F1F1;
    border-radius: 10px;
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 128px;
}

.profile-cards-img-container{
    width: 80px;
    margin: auto 0px;
}

.profile-cards-img{
    border-radius: 50rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    float: right;
    position: relative;
    top: 0px;
    width: 80px;
    height: 80px;
}

.profile-cards-text-container{
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
}

.profile-name{
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    color: #153244;
    margin: 0px;
    font-weight: bold;
}

.profile-email{
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    color: #153244;
    margin: 0px;
}

.profile-role{
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    color: #007BC7;
    margin: 0px;
}

.profile-notifications-container{
    width: 90%;
    margin: 0px 18px;
}

.profile-notifications{
    margin: 0px;
    line-height: normal;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #153244;
}

.profile-notifications-number{
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    color: #007BC7;
    font-weight: bold;
}

.profile-cards-divider{
    width: 90%;
    margin: 8px auto;
}

.profile-date{
    margin: 0px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    color: #153244;
}

.section-table-title{
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #38485C;
}

