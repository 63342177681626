    table.table.table-striped tbody tr td {
        font-weight: 300;
        color: var(--light-steel);
        padding: 5px 10px;
        vertical-align: middle;
        border: none;
        --bs-table-striped-bg: #F9F9F9;
    }

    table.table.table-striped thead tr th {
        padding-bottom: 20px !important;
        border: none;
        color: var(--steel);
    }

    .table-sm>:not(caption)>*>* {
        padding: 0.5rem 0.5rem;
        font-size: .875em;
    }

    .table {
        font: normal normal normal 14px/12px Nunito;
    }