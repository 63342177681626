.pill-card {
    background-color: #FFFFFF;
    border: 2px solid #E6EBF0;
    padding: 0px 8px 0px 20px;
    border-radius: 40px;
    color: #505E70;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}